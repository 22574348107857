import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
			GreenTurf Fields
			</title>
			<meta name={"description"} content={"Где каждый матч ощущается как домашняя игра"} />
			<meta property={"og:title"} content={"GreenTurf Fields"} />
			<meta property={"og:description"} content={"Где каждый матч ощущается как домашняя игра"} />
			<meta property={"og:image"} content={"https://quivurs.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://quivurs.com/img/9840655.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://quivurs.com/img/9840655.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://quivurs.com/img/9840655.png"} />
			<meta name={"msapplication-TileImage"} content={"https://quivurs.com/img/9840655.png"} />
		</Helmet>
		<Components.Header />
		<Section background="#c0c0c0" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text as="h1" margin="12px 0" font="--headline2" md-font="--headline3">
						Наша история
						</Text>
						<Text
							as="p"
							margin="12px 0"
							font="--base"
							color="--grey"
							letter-spacing="1px"
							text-transform="uppercase"
						>
							В компании GreenTurf Fields мы верим, что каждый матч - это не просто игра, это история, которая ждет, чтобы ее рассказали. Наше поле создано для того, чтобы стать местом, где оживают истории триумфа, товарищества и чистой радости футбола. Наше поле, созданное с целью создания общего пространства для любителей футбола, стало свидетелем бесчисленных голов, побед и моментов спортивного мастерства.
						</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://quivurs.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Text
				margin="0px 0px 50px 0px"
				font="--headline2"
				color="--dark"
				sm-font="normal 700 52px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				text-align="center"
			>
				Почему GreenTurf выделяется
			</Text>
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://quivurs.com/img/4.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					Ориентированность на сообщество: Центр для местных любителей футбола.
					<br />
Безупречное обслуживание: Поле, которое всегда готово к матчу.
					<br />
Доступность: Открыт с рассвета до выключения света на стадионе.
					<br />
Конфиденциальность: Бронирование гарантирует эксклюзивный доступ для вашей группы.
					<br />
Простота: Система онлайн-бронирования без лишних хлопот.

				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
					Ваша история - это наше наследие. Будь то дружеские посиделки или ожесточенные соревнования, каждый момент на GreenTurf вписывается в богатый гобелен нашей общей футбольной истории. Создавайте свою главу, оттачивайте мастерство и создавайте моменты, которые будут звучать за пределами поля. Выбирайте GreenTurf, где каждая игра - это не просто матч, а место, где создаются и запоминаются легенды.
					</Text>
					<Button href="/contacts" type="link" text-decoration-line="initial">
					Связаться с нами
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://quivurs.com/img/5.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});